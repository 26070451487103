import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const wrapper = css`
  grid-column: 3 / span 10;

  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
`;

const gap = css`
  margin-bottom: calc(var(--unit) * 10);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 15);
  }
`;

const wrapperSecondary = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: 3 / span 10;
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);

  @media ${breakpoints.large} {
    flex-direction: row;
  }
`;

const gallery = css`
  .image-gallery {
    position: static;
    ${breakpoints.medium} {
      width: 100%;
    }
  }

  .image-gallery-content {
    position: static;
  }

  .image-gallery-slide-wrapper {
    position: static;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    width: 100%;
    max-height: calc(100vh - var(--unit) * 50);
  }

  .image-gallery-slide img {
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
  }

  .image-gallery-index {
    position: absolute;
    bottom: var(--spacing-lrg);
    top: auto;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--font-family-secondary);

    @media ${breakpoints.medium} {
      bottom: 1vh;
    }
  }

  .image-gallery-right-nav {
    right: var(--spacing-xlrg);
    bottom: var(--spacing-med);
    top: auto;

    z-index: 1;
    padding: 0;
    transform: translateY(0%);

    &:hover polyline {
      color: ${colors.borderLight};
    }

    @media ${breakpoints.medium} {
      right: calc(var(--unit) * 17);
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .image-gallery-left-nav {
    position: absolute;

    left: var(--spacing-xlrg);
    bottom: var(--spacing-med);
    top: auto;
    padding: 0;

    &:hover polyline {
      color: ${colors.borderLight};
    }

    z-index: 1;
    transform: translateY(0%);

    @media ${breakpoints.medium} {
      left: calc(var(--unit) * 17);
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const imageWrapper = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  left: 0;
  width: 100%;

  padding-top: calc(var(--unit) * 22);
  margin-bottom: var(--spacing-xxlrg);
`;

const featuredImage = css`
  width: 100%;
  object-fit: cover;
  height: calc(var(--unit) * 80);

  &:nth-of-type(2) {
    display: none;
  }

  &:nth-of-type(3) {
    display: none;
  }

  @media ${breakpoints.medium} {
    width: 49%;
    object-fit: cover;
    height: calc(var(--unit) * 120);

    &:nth-of-type(2) {
      display: inline-block;
    }
  }

  @media ${breakpoints.large} {
    width: 32%;
    height: calc(var(--unit) * 140);
    object-fit: cover;

    &:nth-of-type(3) {
      display: inline-block;
    }
  }
`;

const galleryWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;

  z-index: 3;

  @media ${breakpoints.medium} {
    width: 70%;
  }
`;

const overlay = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  background: ${colors.black};
`;

const overlayHidden = css`
  display: none;
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0.76, 0.98);
  overflow: hidden;
`;

const button = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const buttonHidden = css`
  display: none;
`;

const galleryButtonFullscreen = css`
  position: absolute;
  display: inline-flex;
  bottom: 2vh;
  left: 2vw;

  width: calc(var(--unit) * 30);
  height: calc(var(--unit) * 10);
  padding: var(--spacing-med) var(--spacing-lrg);

  font-family: var(--font-family-secondary);
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  font-weight: var(--font-weight-custom500);

  border: none;
  border-radius: var(--border-radius-small);

  background-color: ${colors.white};
  color: ${colors.text};

  transition: all 0.3s ease-in-out;

  &:hover,
  :active {
    background-color: ${colors.backgroundDark};
  }

  @media ${breakpoints.medium} {
    width: calc(var(--unit) * 45);
    height: calc(var(--unit) * 12);
    padding: var(--spacing-med) var(--spacing-lrg);
  }
`;

const galleryButtonText = css`
  color: ${colors.text};
  margin-right: 0;
`;

const galleryButtonClose = css`
  position: absolute;
  z-index: 3;
  right: var(--spacing-xxlrg);
  top: var(--spacing-xlrg);

  background-color: transparent;
  border: none;

  @media ${breakpoints.medium} {
    right: calc(var(--unit) * 17);
    top: calc(var(--unit) * 12);
  }
`;

const breadcrumbsWrapper = css`
  grid-column: 3 / span 8;
  display: flex;
  align-items: center;

  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);

  color: ${colors.textLight};

  margin-bottom: calc(var(--unit) * 10);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 13);
  }
`;

const breadcrumb = css`
  position: relative;
  min-width: max-content;
  color: ${colors.primary};

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${colors.secondary};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${colors.secondary};
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

const svg = css`
  margin: 0 var(--spacing-med);
`;

const title = css`
  grid-column: 3 / span 6;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xxLarge);
  line-height: var(--line-height-xxLarge);
  font-weight: var(--font-weight-regular);
  margin-bottom: calc(var(--unit) * 10);
  margin-top: 0;

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxxLarge);
    line-height: var(--line-height-xxxLarge);
  }

  @media ${breakpoints.large} {
    line-height: var(--line-height-medium);
  }
`;

const summary = css`
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);

  color: ${colors.textLight};
  margin-bottom: var(--spacing-xxlrg);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 20);
  }
`;

const eventInfoWrapper = css`
  display: flex;

  margin-bottom: var(--spacing-huge);
`;

const eventInfoIcon = css`
  margin-right: var(--spacing-med);

  @media ${breakpoints.medium} {
    margin-right: var(--spacing-lrg);
  }
`;

const eventInfo = css`
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  margin-bottom: var(--spacing-tny);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-large);
  }
`;

const eventInfoCategoryName = css`
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);

  color: ${colors.textLight};
`;

const currentBreadcrumb = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ctaWrapper = css`
  width: 100%;
  max-width: calc(var(--unit) * 75);
  margin-bottom: calc(var(--unit) * 10);

  @media ${breakpoints.medium} {
    max-width: none;
    width: calc(var(--unit) * 80);
  }

  @media ${breakpoints.large} {
    margin: 0 var(--spacing-lrg);
    width: calc(var(--unit) * 85);
  }
`;

const ctaMessage = css`
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-regular);
  margin-bottom: var(--spacing-lrg);

  @media ${breakpoints.large} {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
  }
`;

const cta = css`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding: calc(var(--unit) * 2);

  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);

  color: ${colors.white};
  background-color: ${colors.primary};
  border: none;
  border-radius: var(--border-radius-small);
  transition: all 0.2s ease-in;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.secondary};
  }

  @media ${breakpoints.medium} {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
    padding: calc(var(--unit) * 4);
  }
`;

export const programDetailsHeaderStyles = {
  wrapper,
  wrapperSecondary,
  gallery,
  imageWrapper,
  featuredImage,
  overlay,
  overlayHidden,
  button,
  buttonHidden,
  galleryWrapper,
  galleryButtonFullscreen,
  galleryButtonText,
  galleryButtonClose,
  breadcrumbsWrapper,
  breadcrumb,
  svg,
  title,
  summary,
  eventInfoWrapper,
  eventInfoIcon,
  eventInfo,
  eventInfoCategoryName,
  currentBreadcrumb,
  cta,
  ctaWrapper,
  ctaMessage,
  gap,
};
